export const API_URL = process.env.VUE_APP_API_URL;
export const FILE_URL =
  "https://office.smartremont.kz" || process.env.VUE_APP_FILE_URL;
export const MAIN_SYSTEM_URL = process.env.VUE_APP_MAIN_SYSTEM_URL;

export const PAGES_HEADS = {
  requests: "requests",
  remont: "remont",
  loginUrl: "login-url",
};

export const PAGES = {
  main: "/",
  login: "/login",
  financePayments: "/finance-payments",
  warrantyRequests: "/warranty-requests",
  providerRequests: "/provider-requests",
  balances: "/balances",
  materialProviderList: "/material-provider-list",
  forgetPassword: "/forget-password",
  payments: "/payments",
  compareDataOneC: "/compare-data-one-c",
  timelineGrade: "/timeline-grade",
  reportGenerator: "/report-generator",
  timelineRemont: "/timeline-client-requests",
  changeMaterialsRequests: "/change-materials-requests/:type",
  changeMaterialsRequestsAvail: "/change-materials-requests/AVAILABILITY",
  changeMaterialsRequestsPrice: "/change-materials-requests/PRICE",
  request: `/${PAGES_HEADS.requests}/:client_request_id`,
  remontPage: `/${PAGES_HEADS.remont}/:remont_id`,
  quickSearch: "/quick-search",
  kanban: "/kanban",
  realization: "/realization",
  agreementsJournal: "/contract-journal",
  executiveReports: "/executive-reports",
  handbook: "/handbook/:type",
  handbookWorksets: "/handbook/worksets",
  handbookNoticeTemplates: "/handbook/notice-templates",
  handbookProviders: "/handbook/providers",
  handbookProvidersAgreement: "/handbook/providers-agreement",
  handbookOrgFiles: "/handbook/documents",
  handbookServiceMasters: "/handbook/service-masters",
  handbookMobileApps: "/handbook/mobile-apps",
  handbookGradeActions: "/handbook/grade-actions",
  handbookStepActivities: "/handbook/step-activities",
  handbookTimelineSteps: "/handbook/timeline-steps",
  handbookTimelineStepConditions: "/handbook/timeline-step-conditions",
  handbookGradeTimelineSteps: "/handbook/grade-timeline-steps",
  handbookContractors: "/handbook/handbook-contractors",
  handbookTechprojectDevelopers: "/handbook/techproject-developers",
  loginUrl: `/${PAGES_HEADS.loginUrl}/:token`,
  logging: "/logs",
  profile: "/profile",
  techprojectRequests: "/techproject-requests",
  roomBooking: "/room-booking",
};

export const LOCALSTORAGEFIELDS = {
  accessToken: "accessTokenOffice",
  refreshToken: "refreshTokenOffice",
  fingerToken: "fingerTokenOffice",
  menuDrawerOpen: "menuDrawerOpen",
};

export const MODAL_NAMES = {
  CONFIRM: "ConfirmModal",
  TEXT_MODAL: "modal-text",
  ADD_FINANCE_PAYMENT: "AddFinancePayment",
  CreateAdditionalAgreement: "CreateAdditionalAgreement",
  CHANGE_PAYMENT_STATUS: "ChangePaymentStatus",
  CHANGE_MATERIAL_STATUS: "ChangeMaterialStatus",
  REMONT_TIMELINE_STEP: "RemontTimelineStep",
  GRADE_TIMELINE_STEP: "GradeTimelineStep",
  CHANGE_FINANCE_PAYMENT: "change-finance-payment",
  FINANCE_PAYMENT_DOCS: "FinancePaymentDocs",
  RECEIVE_KEYS_EDIT: "ReceiveKeysEdit",
  REMONT_PROJECT_EDIT: "RemontProjectEdit",
  CHECK_LIST_PHOTOS: "CheckListPhotos",
  Participants_Replacement_History: "ParticipantsReplacementHistory",
  Change_Remont_Responsible: "ChangeRemontResponsible",
  Change_Remont_Grade: "ChangeRemontGrade",
  AddTranche: "AddTranche",
  AddIncome: "AddIncome",
  AddCost: "AddCost",
  AddStage: "AddStage",
  AddRemontService: "AddRemontService",
  AddDoc: "AddDoc",
  AddMobileAppVersion: "AddMobileAppVersion",
  AddRoom: "AddRoom",
  ExecutiveReportComments: "ExecutiveReportComments",
  ExecutiveReportDeadlineView: "ExecutiveReportDeadlineView",
  ExecutiveReportSetDeadline: "ExecutiveReportSetDeadline",
  ExecutiveReportEmployeeSet: "ExecutiveReportEmployeeSet",
  ExecutiveReportNps: "ExecutiveReportNps",
  ExecutiveReportAnalyze: "ExecutiveReportAnalyze ",
  ExecutiveReportWarrantyAccept: "ExecutiveReportWarrantyAccept",
  ExecutiveReportFlatList: "ExecutiveReportFlatList",
  ExecutiveReportOkk: "ExecutiveReportOkk",
  ApartmentOwnersHistory: "ApartmentOwnersHistory",
  ChangeWarrantyStatus: "ChangeWarrantyStatus",
  ChangeWarrantyEmployee: "ChangeWarrantyEmployee",
  ChangeDSSum: "ChangeDSSum",
  ChangeWarrantyRequest: "ChangeWarrantyRequest",
  WarrantyAct: "WarrantyAct",
  WarrantyRequestDocuments: "WarrantyRequestDocuments",
  AddAgreement: "AddAgreement",
  AddGradeAction: "AddGradeAction",
  AddTimelineStep: "AddTimelineStep",
  AddStepCondition: "AddStepCondition",
  AddGradeTimelineStep: "AddGradeTimelineStep",
  NestedGradeTimelineSteps: "NestedGradeTimelineSteps",
  AddStepActivity: "AddStepActivity",
  ChatGallery: "ChatGallery",
  Add_NOTICE_TEMPLATE: "AddNoticeTemplate",
  Add_HANDBOOK_DOCUMENT: "AddHandbookDocument",
  Add_HANDBOOK_PROVIDER: "AddHandbookProvider",
  Add_HANDBOOK_CONTRACTOR: "AddHandbookContractor",
  Add_HANDBOOK_TECHPROJECT_DEVELOPER: "AddHandbookTechprojectDeveloper",
  ADD_PROVIDERS_AGREEMENT: "AddProvidersAgreement",
  UPDATE_BALANCES: "UpdateBalances",
  EditChatMessage: "EditChatMessage",
  EDIT_HANDBOOK_WORKSET: "EditHandbookWorkset",
  ADD_HANDBOOK_CONTRACTOR_WORKSET: "AddHandbookContractorWorkset",
  ADD_WORKSET_PRICE: "AddWorksetPrice",
  ADD_HIDDEN_WORKSET: "AddHiddenWorkset",
  TEXT_CONSTRUCTOR_WORKS: "TextConstructorWorks",
  TEXT_CONSTRUCTOR_MATERIALS: "TextConstructorMaterials",
  TEXT_CONSTRUCTOR_INFO: "TextConstructorInfo",
  TEXT_CONSTRUCTOR_MATERIAL_PRICE_CHANGE: "TextConstructorMaterialPriceChange",
  PROVIDER_REQUESTS_PROVIDER_FORM: "ProviderRequestsProviderForm",
  PROVIDER_REQUESTS_CONTRACTOR_FORM: "ProviderRequestsContractorForm",
  PROVIDER_REQUESTS_SHIPPING_DATE_HISTORY: "ProviderRequestsShippingDateHistory",
  PROVIDER_REQUESTS_SHIPPING_DATE_CHANGE: "ProviderRequestsShippingDateChange",
  PROVIDER_REQUESTS_QTY_DELIVERY_CHNG: "ProviderRequestsQtyDeliveryChange",
  PROVIDER_REQUESTS_INVOICE_ITEM: "ProviderRequestsInvoiceItem",
  PROVIDER_REQUESTS_GENERATE_INVOICE: "ProviderRequestsGenerateInvoice",
  
  PROVIDER_REQUESTS_CHANGE_WAREHOUSE: "ProviderRequestsChangeWarehouse",
  PROVIDER_REQUESTS_SHIP_FROM_WAREHOUSE: "ProviderRequestsShipFromWarehouse",
  PROVIDER_REQUESTS_INVOICE_LIST: "ProviderRequestsInvoiceList",
  PROVIDER_REQUESTS_STATUS_HISTORY: "ProviderRequestsStatusHistory",
  PROVIDER_REQUESTS_REFUND_INVOICES: "ProviderRequestsRefundInvoices",
  PROVIDER_REQUESTS_REFUND_RELEASE: "ProviderRequestsRefundRelease",
  PROVIDER_REQUESTS_MATERIAL_SELECT: "ProviderRequestsMaterialSelect",
};

export const changeStatusesNames = {
  CANCELED: "CANCELED",
  ACCEPTED: "ACCEPTED",
  DENIED: "DENIED",
  PENDING: "PENDING",
};

export const timelineStepsCodes = {
  COMPLETED: "COMPLETED",
  NOT_STARTED: "NOT_STARTED",
  CURRENT: "CURRENT",
};
