<template>
  <div class="time-grid-container">
    <div v-for="(timeGroup, period) in groupedTimes" :key="period" class="time-group">
      <div class="toggle-btn" @click="togglePeriod(period)">
        <span>{{ periodLabels[period] }}</span>
        <i class="fa-solid fa-angle-down" :class="{ rotated: !collapsedPeriods[period] }"></i>
      </div>
      <div v-if="!collapsedPeriods[period]" class="time-grid">
        <template v-if="timeGroup.length">
          <q-btn v-for="time in timeGroup" :key="time.remarket_time_id" class="time-slot"
            :class="{ selected: time.remarket_time_id === selectedTime }" @click="selectTime(time)"
            :loading="roomsLoadingInner === time.remarket_time_id">
            {{ time.time_gap }}
          </q-btn>
        </template>
        <template v-else>
          <p>
            {{ period === "morning" ? "Нет доступного времени с утра" : "Нет доступного времени в обед" }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, watch } from "vue";
const props = defineProps(["times", "selectedTime", "roomsLoadingInner"]);
const emit = defineEmits(["time-selected"]);

const selectedTime = ref(props.selectedTime);

watch(() => props.selectedTime, (newVal) => {
  selectedTime.value = newVal;
});

const collapsedPeriods = ref({
  morning: false,
  afternoon: false,
  // evening: true,
});

const periodLabels = {
  morning: "Утро",
  afternoon: "День",
  // evening: "Вечер",
};

const groupedTimes = computed(() => {
  return {
    morning: props.times.filter(time => parseInt(time.time_gap.split(':')[0]) < 12),
    afternoon: props.times.filter(time => parseInt(time.time_gap.split(':')[0]) >= 12 && parseInt(time.time_gap.split(':')[0]) < 18),
    // evening: props.times.filter(time => parseInt(time.time_gap.split(':')[0]) >= 18),
  };
});

const togglePeriod = (period) => {
  collapsedPeriods.value[period] = !collapsedPeriods.value[period];
};

const selectTime = (time) => {
  selectedTime.value = time.remarket_time_id;
  emit("time-selected", time);
};
</script>

<style scoped>
.time-grid-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.time-group {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.toggle-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  font-weight: bold;
}

.toggle-btn i.rotated {
  transform: rotate(180deg);
}

.time-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.time-slot {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}

.time-slot:hover {
  background-color: #d0d0d0;
}

.time-slot.selected {
  background-color: rgb(25, 118, 210);
  /* primary color */
  color: white;
}
</style>
